import React from 'react';
import './ImageCard.css';


const ImageCard = (props) => {
    return (
        <div className={`image-card--background ${props.imageTilt} ${props.backgroundChoice}`}>

            <div 
                className={`image-card--image ${props.imageSize}`}
                style={ props.imageChoice }>
            </div>
            
        </div>
    );
}

export default ImageCard;
