import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import './HeroSection.css';


const HeroSection = (props) => {
    return (
        <div className='hero-section'>
            <div className='hero-outer-container'>
                <div className='hero-inner-container'>

                    <div className='hero-image' />

                    {/* <p className='hero-title'>
                        UbiTheraPlay
                    </p> */}
                    
                    <p className='hero-subtitle'>
                        The medical gaming platform for children with neurological disorders affecting motor functions.
                    </p>

                    <div className='hero-btn-signup'>
                        <Link to='/contact'>
                            <Button 
                                onClick= {() => {}}
                                buttonStyle='btn--primary'
                                buttonSize='btn--large'
                                text='Contact Us'
                            />
                        </Link>
                    </div>
                    
                    <div className='hero-btn-discover'>
                        <Button
                            onClick= {() => {props.discoverMoreScroll()}}
                            buttonStyle='btn--outline'
                            buttonSize='btn--large'
                            text='Discover More'  
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HeroSection