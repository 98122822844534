import React, {useState} from 'react';
import './Landing.css';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/index';

import HeroSection from '../components/HeroSection';
import ImageCard from '../components/ImageCard';
import Button from '../components/Button';
import NewsCard from '../components/NewsCard';

import IntroCard1 from '../assets/Intro-Card-1.svg';
import IntroCard2 from '../assets/Intro-Card-2.svg';
import IntroCard3 from '../assets/Intro-Card-3.svg';

import PackagesCard1 from '../assets/Landing-Packages-Hospital.svg';
import PackagesCard2 from '../assets/Landing-Packages-Home.svg';

import Partner1 from '../assets/Partner-Ohcat.png';
import Partner2 from '../assets/Partner-Bedelsford.jpg';
import Partner3 from '../assets/Partner-Dhulikhel.png';
import Partner4 from '../assets/Partner-Cpotential.png';
import Partner5 from '../assets/Partner-Kingston.png';

import UbiTech from '../assets/Footer-Ubitech-Logo.svg';

import { newsArray } from '../data.js';


const scrollToIntro = () => {

    let offset;
    
    if (
        window.matchMedia("(max-width: 680px)").matches &&
        window.matchMedia("(orientation: landscape)").matches
    ) {
        offset = 60;
    } 
    else if (window.matchMedia("(min-width: 961px)").matches) {
        offset = -100;
    } 
    else if (window.matchMedia("(min-width: 821px)").matches) {
        offset = -140;
    }
    else if (window.matchMedia("(min-width: 300px)").matches) {
        offset = -140;
    }
    else {
        offset = 0;
    }


    window.scrollTo(
        {
            top: document.getElementById('intro-start').getBoundingClientRect().top + window.pageYOffset + (offset),
            behavior: 'smooth'
        }
    );
};


const Landing = () => {

    const [news] = useState(true);

    const dispatch = useDispatch();
    const { 
        selectArticle, 
        showArticle, 
        hideArticle 
    } = bindActionCreators(actionCreators, dispatch);


    var newsArrayJSX = [];

    newsArray.slice(-3).forEach(function(obj) {
        newsArrayJSX.push(
            <NewsCard 
                key={obj.id}
                imageChoice={{
                    backgroundImage: `url(${obj.image})`
                }}
                title={obj.title}
                content={obj.description}
                buttonText='Read More'
                formatting='landing-page'
                linkTo='news'
                buttonClick={() => {
                    selectArticle(obj.id);
                    showArticle();
                }}
            />
        );
    });



    return (
        <>
            <HeroSection 
                discoverMoreScroll={scrollToIntro}
            />

            <div id='intro-start' className='flexbox-basic'>
                <div className='landing-intro--container'>

                    <h1 className='landing-intro--title'>
                        What is UbiTheraPlay?
                    </h1>

                    <p className='landing-intro--description'>
                        UbiTheraPlay combines performing a physical activity within the context of playing video games. These 
                        games provide a fun and motivational platform for patients with motor impairment, whilst delivering 
                        therapeutic benefits under the supervision of healthcare professionals. The gaming solutions utilise 
                        balance boards as the player interface to encourage the appropriate physical activity 
                        in the context of gameplay. This solution has been initially targeted at young people with Cerebral 
                        Palsy; however, research shows that these methods can be applied to other forms of neurological disorders 
                        and health conditions.
                    </p>

                    <div className='landing-intro--card-section'>

                        <div className='landing-intro--card-section--card-1'>
                            <ImageCard
                                imageSize='intro-section-card'
                                imageChoice={{
                                    backgroundImage: `url(${IntroCard1})`
                                }}
                            />  
                        </div>
                        <h1 className='landing-intro--card-section--title-1'>
                            Play
                        </h1>
                        <p className='landing-intro--card-section--description-1'>
                            With a Balance Board, Internet and PC you can play in a clinic, school, or at home.
                        </p>

                        <div className='landing-intro--card-section--card-2'>
                            <ImageCard
                                imageSize='intro-section-card'
                                imageChoice={{
                                    backgroundImage: `url(${IntroCard2})`
                                }}
                            />
                        </div>
                        <h1 className='landing-intro--card-section--title-2'>
                            Manage
                        </h1>
                        <p className='landing-intro--card-section--description-2'>
                            Store gameplay information allowing for player progression analysis. 
                        </p>

                        <div className='landing-intro--card-section--card-3'>
                            <ImageCard
                                imageSize='intro-section-card'
                                imageChoice={{
                                    backgroundImage: `url(${IntroCard3})`
                                }}
                            />
                        </div>
                        <h1 className='landing-intro--card-section--title-3'>
                            Act
                        </h1>
                        <p className='landing-intro--card-section--description-3'>
                            Medical professionals assess motor function performance over time and fine-tune therapy.
                        </p>

                    </div>
                </div>
            </div>

            <div className='flexbox-basic'>
                <div className='landing-description--container'>
                    <h1 className='landing-description--title'>Who can benefit from UbiTheraPlay?</h1>
                    <p className='landing-description--paragraph-1'>
                        The UbiTheraPlay suite provides tailor-made video games for the therapeutic support of children 
                        with motor impairment. The target markets are specialised schools and clinics supporting the 
                        therapeutic care of mobility impaired children of 5-18 years with motor impairment.
                    </p>
                </div>
            </div>

            <div className='flexbox-basic'>
                <div className='landing-description--container'>
                    <h1 className='landing-description--title'>How It Works</h1>
                    <div className='landing-description--paragraph-1'>
                        Through the employment of UbiTheraPlay's cutting edge technology and use of a balance board, children and youths are able to engage in a variety of stimulating and entertaining games whilst simultaneoulsy encouraging gross motor function.
                        The benefits of using UbiTheraPlay are centered around the improvement of the patient's gross motor functions and the ability to record and analyze the progress made from their sessions, providing insight with regards to the patient's rehabilitation. <br/>
                    </div>
                    <div className='landing-description--paragraph-2'>
                        UbiTheraPlay technology is based on research undertaken in the EU Funded Gable project 
                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='https://content.iospress.com/articles/journal-of-pediatric-rehabilitation-medicine/prm190666' target='_blank' rel='noopener noreferrer'>
                                <sup>[1]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>
                                [1] Kachmar, O., Kushnir, A., Fedchyshyn, B., Cristiano, J., O’Flaherty, J., Helland, K., Johnson, G. and Puig, D., 2021. Personalized balance games for children with cerebral palsy: a pilot study. Journal of pediatric rehabilitation medicine, (Preprint), pp.1-9.
                            </span>
                        </div> and GCRF Funded Gable-Ext project
        
                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='https://wwrf45.wsconferences.com/agenda/' target='_blank' rel='noopener noreferrer'>
                                <sup>[2]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>
                                [2] Johnson, Gordon, Vasileios Argyriou, and Christos Politis (2021). “Framework for Calibrating a Balance Board for Training Exergames with Equity between Players of Varying Abilities”. In: Wireless World Research Forum - Hyper Connectivity.
                            </span>
                        </div>
                        . We also use the following scientific research papers in building our technology 
                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='http://llibres.urv.cat/index.php/purv/catalog/view/358/371/854-1' target='_blank' rel='noopener noreferrer'>
                                <sup>[3]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>
                                [3] Benefits of the Statistical and Machine Learning Models in Games Designed for Children with Cerebral Palsy Author(s): Saddam Abdulwahab Published in: 5TH URV DOCTORAL WORKSHOP IN COMPUTER SCIENCE AND MATHEMATICS, 2019, Page(s) 83-86 
                            </span>
                        </div>

                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='https://link.springer.com/chapter/10.1007/978-3-030-02762-9_29' target='_blank' rel='noopener noreferrer'>
                                <sup>[4]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>    
                                [4] Instant Measurement of the Difficulty Level of Exergames with Simple Uni-dimensional Level Goals for Cerebral Palsy Players Author(s): Mohammad Rahmani, Blas Herrera, Oleh Kachmar, Julián Cristiano, Domenec Puig Published in: Serious Games - 4th Joint International Conference, JCSG 2018, Darmstadt, Germany, November 7-8, 2018, Proceedings, Issue 11243, 2018, Page(s) 276-281 DOI: 10.1007/978-3-030-02762-9_29
                            </span>
                        </div>
                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='https://zenodo.org/record/3594824#.YKJ6g6hKiUk' target='_blank' rel='noopener noreferrer'>
                                <sup>[5]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>    
                                [5] Performance of users with Cerebral Palsy playing GABLE Games together with their results to the Left/Right Dynamic balance tool Author(s): Cristiano, Julián; Domènec Puig; Abdulwahab, Saddam; Kachmar, Oleh; Kushnir, Anna; O'Flaherty, John; Helland, Kjetil; Johnson, Gordon; Politis, Christos Published in: Zenodo https://zenodo.org/record/3594824#.YKJ6g6hKiUk
                            </span>
                        </div>
                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='http://llibres.urv.cat/index.php/purv/catalog/download/275/311/689-1?inline=1' target='_blank' rel='noopener noreferrer'>
                                <sup>[6]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>    
                                [6] GABLE: GAmification for a Better LifE Author(s): Hamed H. Aghdam, Domènec Puig, Julián Cristiano Published in: 4TH URV DOCTORAL WORKSHOP IN COMPUTER SCIENCE AND MATHEMATICS, 2017, Page(s) 15-18
                            </span>
                        </div>
                        <div className='landing-description--tooltip'>
                            <a className='landing-description--tooltip-a' href='https://www.scitepress.org/PublicationsDetail.aspx?ID=L0mxA99blY4=&t=1' target='_blank' rel='noopener noreferrer'>
                                <sup>[7]</sup>
                            </a>
                            <span className='landing-description--tooltip-text'>    
                                [7] GABLE - GAmification for a Better LifE Author(s): Julián Cristiano, Mohammad Rahmani, Kjetil Helland, Domènec Puig Published in: OPPORTUNITIES AND CHALLENGES for European Projects, 2017, Page(s) 124-132 DOI: 10.5220/0008862401240132 
                            </span>
                        </div>.
                    </div>
                    <div className='landing-description--paragraph-2'>
                        Patients using UbiTheraPlay will generally be under the care of a professional therapist to supervise 
                        gaming and use the game data to assist their patient monitoring. UbiTheraPlay provides a custom calibration 
                        process allowing the therapist to calibrate the games to the patients’ ability to ensure maximum patient 
                        benefit and their motivation to play the games. Gameplay data can be confidentially accessed by the 
                        therapist to view game performance with customisable case reports providing clinical metrics to assess and 
                        monitor patient improvement through gameplay.
                    </div>
                </div>
            </div>

            <div className='flexbox-basic'>
                <div className='landing-description--container'>

                    <h1 className='landing-description--title'>How To Get Started</h1>
                    <p className='landing-description--paragraph-1'>
                        The patient or the patient’s institution requires some standard equipment and licensed access
                        to UbiTheraPlay suite. The equipment hardware is a Nintendo Wii Balance Board and Windows
                        PC computer or laptop (Windows 10, CPU i5 or equivalent, RAM 8GB, Bluetooth, Internet
                        access). A large monitor (27”+) or TV is recommended.
                    </p>
                    <p className='landing-description--paragraph-1'>
                        Patients using UbiTheraPlay will generally be under the care of a professional therapist to supervise 
                        gaming and use the game data to assist their patient monitoring. UbiTheraPlay provides a custom calibration 
                        process allowing the therapist to calibrate the games to the patients’ ability to ensure maximum patient 
                        benefit and their motivation to play the games. Gameplay data can be confidentially accessed by the 
                        therapist to view game performance with customisable case reports providing clinical metrics to assess and 
                        monitor patient improvement through gameplay.
                    </p>

                </div>
            </div>

            <div className='flexbox-basic'>
                    <div className='landing-packages--container'>

                        <h1 className='landing-packages--title'>Our Packages</h1>
                        
                        <div className='landing-packages--grid'>

                            <p className='landing-packages--grid--1-1 landing-packages--grid--title'>Clinic Plan</p>
                            <div className='flexbox-basic landing-packages--grid--1-2'>
                                <ImageCard
                                    backgroundChoice='background--packages'
                                    imageSize='intro-section-card'
                                    imageChoice={{
                                        backgroundImage: `url(${PackagesCard1})`
                                    }}
                                />  
                            </div>
                            <p className='landing-packages--grid--1-3 landing-packages--grid--p'>For use at Clinics, Hospitals, and Specialist Schools</p>

                            <p className='landing-packages--grid--2-1 landing-packages--grid--title'>Home Plan</p>
                            <div className='flexbox-basic landing-packages--grid--2-2'>
                                <ImageCard
                                    backgroundChoice='background--packages'
                                    imageSize='intro-section-card'
                                    imageChoice={{
                                        backgroundImage: `url(${PackagesCard2})`
                                    }}
                                />  
                            </div>
                            <p className='landing-packages--grid--2-3 landing-packages--grid--p'>For Home use with supervision of Clinic or Professional</p>
                        
                        </div>

                        <div className='landing-packages--button'>
                            <Link to='/contact'>
                                <Button 
                                    onClick= {() => {}}
                                    buttonStyle='btn--primary'
                                    buttonSize='btn--large'
                                    text='Contact Us Now'
                                />
                            </Link>
                        </div>

                    </div>
            </div>

            <div className='flexbox-basic'>
                    <div className='landing-games--container'>

                        <h1 className='landing-games--title'>Our Software</h1>
                        <p className='landing-games--subtitle'>What our current software suite consists of</p>
                        
                        <p className='landing-games--paragraph-1'>Portal – Web application which allows us to manage our user accounts.</p>

                        <p className='landing-games--paragraph-2'>Client – Windows application handling the downloading, launching, and updating of games.</p>

                        <p className='landing-games--paragraph-2'>Games – We currently offer three specialized exer-games.</p>

                        <p className='landing-games--paragraph-2'>Tools – We currently have a baseline application for measuring body sway.</p>

                        <div className='landing-games--button'>
                            <Link to='/games'>
                                <Button 
                                    onClick= {() => {}}
                                    buttonStyle='btn--primary'
                                    buttonSize='btn--large'
                                    text='More about our Games'
                                />
                            </Link>
                        </div>

                    </div>
            </div>

            <div className={news ? 'flexbox-basic' : 'landing-news--hidden'}>
                <div className='landing-news--container'>

                    <h1 className='landing-news--title'>Our Latest News</h1>
                    <p className='landing-news--subtitle'>Check out our most recent posts</p>

                    <div className='landing-news--card-section'>
                        {newsArrayJSX}
                    </div>
                    <div className='landing-news--button'>
                        <Link to='/news'>
                            <Button 
                                onClick= {() => {hideArticle();}}
                                buttonStyle='btn--primary'
                                buttonSize='btn--large'
                                text='More News'
                            />
                        </Link>
                    </div>
                </div>
            </div>

            <div className='flexbox-basic'>
                <div className='landing-partners--container'>

                    <h1 className='landing-partners--title'>Our Partners, Affilliates, and Clients</h1>
                    <p className='landing-partners--subtitle'>Who we work with around the globe</p>

                    <div className='landing-partners--partner-list'>

                        <a href='https://www.ohcat.org/' target='_blank' rel='noopener noreferrer'>
                            <img 
                                className='landing-partners--partner-list-image-1' 
                                src={Partner1} 
                                alt='Orchard Hill College Academy Trust' 
                                width='240'
                                height='100'
                            />
                        </a>
                        <a href='https://www.kingston.ac.uk/' target='_blank' rel='noopener noreferrer'>
                            <img 
                                className='landing-partners--partner-list-image-5' 
                                src={Partner5} 
                                alt='Kingston University'
                                width='160'
                                height='160'
                            />
                        </a>
                        <a href='https://bedelsfordschool.org/' target='_blank' rel='noopener noreferrer'>
                            <img 
                                className='landing-partners--partner-list-image-2' 
                                src={Partner2} 
                                alt='Bedelsford School' 
                                width='200'
                                height='85'
                            />
                        </a>
                        <a href='https://cpotential.org.uk/' target='_blank' rel='noopener noreferrer'>
                            <img 
                                className='landing-partners--partner-list-image-4' 
                                src={Partner4} 
                                alt='CPotential'
                                width='300'
                                height='90'
                            />
                        </a>
                        <a href='https://www.dhulikhelhospital.org/' target='_blank' rel='noopener noreferrer'>
                            <img 
                                className='landing-partners--partner-list-image-3' 
                                src={Partner3} 
                                alt='Dhulikhel Hospital' 
                                width='300'
                                height='60'
                            />
                        </a>

                    </div>

                    <p className='landing-partners--paragraph'>Find out more on the parent company UbiTech website</p>

                    <a className='landing-partners--ubitech' href='https://ubitechit.com/' target='_blank' rel='noopener noreferrer'>
                        <img 
                            className='landing-partners--ubitech-logo' 
                            src={UbiTech} 
                            alt='UbiTech'
                            width='140'
                            height='140'
                        />
                    </a>

                </div>
            </div>

            <div className='flexbox-basic'>
                <div className='landing-contact--container'>
                    <h1 className='landing-contact--title'>
                        Contact Us Now
                    </h1>
                    <p className='landing-contact--subtitle'>
                        If you want to ask a question, would like to know more about UbiTheraPlay, or explore ways to
                        work together please contact us now.
                    </p>
                    <div className='landing-contact--button'>
                        <Link to='/contact'>
                            <Button 
                                onClick= {() => {}}
                                buttonStyle='btn--primary'
                                buttonSize='btn--x-large'
                                text='Contact'
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Landing;
