import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import './Navbar.css';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/index';


const Navbar = () => {

    const mobileNavMenu = useSelector(state => state.mobileNavMenu);


    const dispatch = useDispatch();
    const { openNav, closeNav, hideArticle } = bindActionCreators(actionCreators, dispatch);


    const mobileNav = () => {
        if (mobileNavMenu === false) {
            openNav();
            document.body.style.overflow = 'hidden';
        }
        else {
            closeNav();
            document.body.style.overflow = 'unset';
        }
    }

    const closeNavOnClick = () => {
        closeNav();
        document.body.style.overflow = 'unset';
    }

    window.addEventListener("resize", (e) => {
        closeNavOnClick();
    });

    window.addEventListener('scroll', (e) => {

        const nav = document.querySelector('.navbar');

        if (window.pageYOffset > 0) {
            nav.classList.add('navbar-shadow');
        }
        else {
            nav.classList.remove('navbar-shadow');
        }

    });


    return (
        <>
            <nav className='navbar'>
                <div className="navbar-container">

                    <Link to='/' className='navbar-logo-link' onClick={closeNavOnClick}>
                        <div className='navbar-logo-image' />
                        <div className='navbar-logo-text'>UbiTheraPlay</div>
                    </Link>

                    <div className='navbar-menu-icon' onClick={mobileNav}>
                        <i className={mobileNavMenu ? 'fas fa-times navbar-times-icon': 'fas fa-bars navbar-bars-icon'} />
                    </div>

                    <ul className={mobileNavMenu ? 'navbar-menu active' : 'navbar-menu'}>
                        <li className='navbar-item'>
                            <Link to='/' className='navbar-links'>
                                <Button 
                                    onClick={closeNavOnClick}
                                    buttonStyle='btn--no-outline'
                                    buttonSize='btn--navbar'
                                    text='Overview'
                                />
                            </Link>
                        </li>
                        <li className='navbar-item'>
                            <Link to='/games' className='navbar-links'>
                                <Button 
                                    onClick={closeNavOnClick}
                                    buttonStyle='btn--no-outline'
                                    buttonSize='btn--navbar'
                                    text='Software'
                                />
                            </Link>
                        </li>
                        <li className='navbar-item'>
                            <Link to='/news' className='navbar-links'>
                                <Button 
                                    onClick={() => {closeNavOnClick(); hideArticle();}}
                                    buttonStyle='btn--no-outline'
                                    buttonSize='btn--navbar'
                                    text='News'
                                />
                            </Link>
                        </li>
                        <li className='navbar-item'>
                            <a className='navbar-links' href='https://app.ubitheraplay.com/login' target='_blank' rel='noopener noreferrer'>
                                <Button 
                                    onClick={closeNavOnClick}
                                    buttonStyle='btn--no-outline'
                                    buttonSize='btn--navbar'
                                    text='Portal'
                                />
                            </a>
                        </li>
                        <li className='navbar-item'>
                            <Link to='/contact' className='navbar-links'>
                                <Button 
                                    onClick={closeNavOnClick}
                                    buttonStyle='btn--outline'
                                    buttonSize='btn--navbar-contact'
                                    text='Contact'
                                />
                            </Link>
                        </li>
                    </ul>

                </div>
            </nav>
        </>
    )
}

export default Navbar
