import NewsCard1 from './assets/News-1.jpg';
import NewsCard2 from './assets/News-2.jpg';
import NewsCard3 from './assets/News-3.jpg';

export const newsArray = [
    {
        'id': 0,
        'image': NewsCard1,
        'title': 'New Game Out Soon',
        'description': 'We are currently developing a new educational game to be added to our suite of exer-games this year.',
        'paragraph' : 'We are currently building a new educational game to be used to support the learning process for young people with neurological disorders. The game will be released in July 2021. Visit us again soon for more news on the games release.'
    },
    {
        'id': 1,
        'image': NewsCard2,
        'title': 'Our New Trials in Nepal',
        'description': 'We have officially started our clinical trials in the excellent Kathmandu University Hospital in Nepal.',
        'paragraph' : 'Ubitech and Kingston University London are working together with Dhulikhel University Nepal under a UK GCRF funded project to continue trialing our current suite of exer-games in the Dhulikel University in Nepal.'
    },
    {
        'id': 2,
        'image': NewsCard3,
        'title': 'Orchard Hill Partnership',
        'description': 'Ubitech and Orchard Hill Academy are working together to trial our games in a real world environment.',
        'paragraph' : 'Kingston University and Ubitech are working together with Bedelsford School of the Orchard Hill Academy to trial our current suite of exer-games with children aged 5 to 15.'
    }
];
