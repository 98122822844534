import React, { useEffect } from 'react';
import { 
    BrowserRouter as Router, 
    Switch, 
    Route,
    useLocation,
    withRouter
} from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';

import Landing from './pages/Landing';
import Games from './pages/Games';
import News from './pages/News';
import Contact from './pages/Contact';
import Footer from './components/Footer';


window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop)


function App() {

    return (
        <>
            <Router>
                <ScrollToTop>
                    <Navbar />
                    <Switch>
                        <Route path='/' exact component={Landing}/>
                        <Route path='/games' component={Games}/>
                        <Route path='/news' component={News}/>
                        <Route path='/contact' component={Contact}/>
                    </Switch>
                    <Footer />
                </ScrollToTop>
            </Router>
        </>
    );
}

export default App;
