import React, { useState } from 'react';
import './Contact.css';
import Button from '../components/Button';
import Axios from "axios";

const Contact = () => {

    const [feedback, setFeedback] = useState(false);

    const [response, setResponse] = useState(null);
    const [firstNameValue, setFirstNameValue] = useState("");
    const [lastNameValue, setLastNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [subjectValue, setSubjectValue] = useState("");
    const [messageValue, setMessageValue] = useState("");

    var validator = require("email-validator");


    const mailerAction = async (name, email, subject, message, callback) => {
        
        if (name !== "" &&
            validator.validate(email) &&
            subject !== "" &&
            message !== ""
        ) {
            const body = {
            Name: name,
            Email: email,
            Subject: subject,
            Message: message,
            };
            const url = 'https://api.ubitheraplay.com/api/mailer/contact';
            console.log(url);
            try {
                await Axios.post(url, body).then((result) => {
                    if (result) {
                        callback(result.data.message, result.status);
                        alert('Message sent successfully');
                        window.location.reload();
                    }
                    else {
                        callback("No email sent", 500);
                        console.warn("No email sent");
                    }
                });
            }
            catch (e) {
                console.error(e);
            }
        }
        else {
            setFeedback(true);
        }
    };


    return (
        <div className='contact'>
            <div className='contact--flex-container'>
                <h1 className='contact--title'>Contact Us</h1>
                <p className='contact--subtitle'>Get in contact with us now to Sign Up</p>

                <div className='contact--form'>
                    <div className='contact--form--first-name'>
                        <input className='contact--form--input' type='text' name='first-name' value={firstNameValue} onChange={(e) => setFirstNameValue(e.target.value)} placeholder='First Name' />
                    </div>
                    <div className='contact--form--last-name'>
                        <input className='contact--form--input' type='text' name='last-name' value={lastNameValue} onChange={(e) => setLastNameValue(e.target.value)} placeholder='Last Name' />
                    </div>
                    <div className='contact--form--email'>
                        <input className='contact--form--input' type='email' name='email' value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder='Email Address' />
                    </div>
                    <div className='contact--form--subject'>
                        <input className='contact--form--input' type='text' name='subject' value={subjectValue} onChange={(e) => setSubjectValue(e.target.value)} placeholder='Subject' />
                    </div>
                    <div className='contact--form--message'>
                        <textarea className='contact--form--textarea' name='message' rows='6' value={messageValue} onChange={(e) => setMessageValue(e.target.value)} placeholder='Message' />
                    </div>
                    <div className={feedback ? 'contact--form--feedback': 'contact--form--feedback-none'}>
                        <p>* Please fill out all fields correctly before clicking submit</p>
                    </div>
                    <div className='contact--form--submit'>
                        <Button 
                            onClick={ () => {
                                mailerAction(
                                    `${firstNameValue} ${lastNameValue}`, 
                                    emailValue, 
                                    subjectValue, 
                                    messageValue, 
                                    (msg, status) => { 
                                        setResponse( { msg, status } ) 
                                    }
                                )
                            }}
                            buttonStyle='btn--primary'
                            buttonSize='btn--medium'
                            text='Submit'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
