import React from 'react';
import './Games.css';
import ImageCard from '../components/ImageCard';

import GamesCard1 from '../assets/Games-1.png';
import GamesCard2 from '../assets/Games-2.png';
import GamesCard3 from '../assets/Games-3.png';


const Games = () => {
    return (
        <>
            <div className='software'>
                <div className='software--container'>
                    <h1 className='software--title'>Software</h1>
                    <p className='software--subtitle'>Our unique software suite</p>

                    <p className='software--paragraph'>Our software suite includes the portal, the client, and our range of exer-games.</p>
                </div>
            </div>

            <div className='games'>
                <div className='games--outer-container'>
                    <div className='games--container'>

                        <h1 className='games--title'>Our Games</h1>
                        <p className='games--subtitle'>We currently offer three specialized exer-games</p>
                        
                        <div className='games--background'>

                            <div className='games--game-1'>
                                <div className='games--game-1--card'>
                                    <ImageCard
                                        imageSize='games-section-card'
                                        imageChoice={{
                                            backgroundImage: `url(${GamesCard1})`
                                        }}
                                        imageTilt='tilt-left'
                                    />
                                </div>
                                <h1 className='games--game-1--title'>
                                    Runner eXer
                                </h1>
                                <p className='games--game-1--description'>
                                    This game controls a walking character moving from side to side, 
                                    encouraging left and right leaning movements, with the objectives 
                                    of dynamic accuracy and static stability.
                                </p>
                            </div>

                            <div className='games--game-2'>
                                <div className='games--game-2--card'>
                                    <ImageCard
                                        imageSize='games-section-card'
                                        imageChoice={{
                                            backgroundImage: `url(${GamesCard2})`
                                        }}
                                        imageTilt='tilt-right'
                                    />
                                </div>
                                <h1 className='games--game-2--title'>
                                    Exermaze
                                </h1>
                                <p className='games--game-2--description'>
                                    The objective is to travel around the maze collecting all the tokens before exiting. 
                                    This game develops four directional lean movements and pressure control.
                                </p>
                            </div>

                            <div className='games--game-3'>
                                <div className='games--game-3--card'>
                                    <ImageCard
                                        imageSize='games-section-card'
                                        imageChoice={{
                                            backgroundImage: `url(${GamesCard3})`
                                        }}
                                        imageTilt='tilt-left'
                                    />
                                </div>
                                <h1 className='games--game-3--title'>
                                    Spark in Space
                                </h1>
                                <p className='games--game-3--description'>
                                    This is a space travel game with the goal to collect gems while 
                                    avoiding obstacles. This game develops left and right leaning 
                                    movements and pressure control in a dynamic setting.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Games;
