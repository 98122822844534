import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import UbiTech from '../assets/Footer-Ubitech-Logo.svg';


const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer--container'>

                <div className='footer--ubitheraplay'>
                    <Link to='/' className='footer--ubitheraplay--link'>
                        UbiTheraPlay
                    </Link>
                </div>

                <div className='footer--nav-links'>
                    <ul className='footer--nav-links--ul'>
                        <li>
                            <Link to='/games' className='footer--nav-links--link'>
                                Games
                            </Link>
                        </li>
                        <li>
                            <Link to='/news' className='footer--nav-links--link'>
                                News
                            </Link>
                        </li>
                        <li>
                            <a className='footer--nav-links--link' href='https://app.ubitheraplay.com/login' target='_blank' rel='noopener noreferrer'>
                                Portal
                            </a>
                        </li>
                        <li>
                            <Link to='/contact' className='footer--nav-links--link'>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>

                {/*<Link to='https://ubitechit.com/' className='footer--ubitech-logo' /> */}
                {/*<a className='footer--ubitech-logo' target='_blank' rel="noopener noreferrer" href='https://ubitechit.com/' /> */}
                <a className='footer--ubitech' target='_blank' rel="noopener noreferrer" href='https://ubitechit.com/'>
                    <img className='footer--ubitech-logo' alt='UbiTech' src={UbiTech} />
                </a>
                <div className='footer--ubitech-address'>
                    <p className='footer--ubitech-address--p'>Penrhyn Road</p>
                    <p className='footer--ubitech-address--p'>Kingston upon Thames</p>
                    <p className='footer--ubitech-address--p'>Surrey</p>
                    <p className='footer--ubitech-address--p'>KT1 2EE</p>
                </div>

                <div className='footer--copyright'>&copy; 2021 Ubitech Ltd</div>

            </div>
        </div>
    );
}

export default Footer;
